var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { BsPen, BsSearch } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { GrFilter } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import Filter from "../../components/Filter";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import api from "../../services/api";
import { Table } from 'template/lib';
import styles from './styles.module.scss';
var Dashboard = function () {
    var _a = useAuth(), login = _a.login, handleLogout = _a.handleLogout;
    var setTitle = useTitle().setTitle;
    var history = useHistory();
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    //assignedToMe
    var _b = useState([]), helps = _b[0], setHelps = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(15), pageSize = _d[0], setPageSize = _d[1];
    var _e = useState(0), totalPages = _e[0], setTotalPages = _e[1];
    var _f = useState(false), hasNext = _f[0], setHasNext = _f[1];
    var _g = useState(false), filter = _g[0], setFilter = _g[1];
    var _h = useState([]), filterVal = _h[0], setFilterVal = _h[1];
    var _j = useState(), filterState = _j[0], setFilterState = _j[1];
    var _k = useState(true), loading = _k[0], setLoading = _k[1];
    //notAssigned
    var _l = useState([]), helpsNotAssigned = _l[0], setHelpsNotAssigned = _l[1];
    var _m = useState(1), pageNotAssigned = _m[0], setPageNotAssigned = _m[1];
    var _o = useState(5), pageSizeNotAssigned = _o[0], setPageSizeNotAssigned = _o[1];
    var _p = useState(0), totalPagesNotAssigned = _p[0], setTotalPagesNotAssigned = _p[1];
    var _q = useState(false), hasNextNotAssigned = _q[0], setHasNextNotAssigned = _q[1];
    var _r = useState(false), filterNotAssigned = _r[0], setFilterNotAssigned = _r[1];
    var _s = useState([]), filterValNotAssigned = _s[0], setFilterValNotAssigned = _s[1];
    var _t = useState(), filterStateNotAssigned = _t[0], setFilterStateNotAssigned = _t[1];
    var _u = useState(true), loadingNotAssigned = _u[0], setLoadingNotAssigned = _u[1];
    useEffect(function () {
        setTitle('Dashboard');
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
        setLoadingNotAssigned(true);
        api.get('/helps/helpFilters', config).then(function (response) {
            try {
                setFilterValNotAssigned(response.data);
            }
            catch (e) {
                handleLogout();
            }
        });
        //creating the filter route 
        var urlTotalPagesNotAssigned = "/supports/pendingHelpsAssignedToTotalPages?pagesize=" + pageSizeNotAssigned;
        var urlPendingHelpsNotAssigned = "supports/pendingHelpsAssignedTo?page=" + pageNotAssigned + "&pagesize=" + pageSizeNotAssigned;
        if (filterStateNotAssigned) {
            for (var key in filterStateNotAssigned) {
                var value = filterStateNotAssigned[key];
                if (value != null && value != undefined && value != '') {
                    urlTotalPagesNotAssigned += "&" + key + "=" + value;
                    urlPendingHelpsNotAssigned += "&" + key + "=" + value;
                }
            }
        }
        api.get(urlTotalPagesNotAssigned, config)
            .then(function (response) {
            setTotalPagesNotAssigned(response.data);
            try {
                if (response.data > pageNotAssigned) {
                    setHasNextNotAssigned(true);
                }
                else {
                    setHasNextNotAssigned(false);
                }
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        });
        api.get(urlPendingHelpsNotAssigned, config)
            .then(function (response) {
            try {
                setHelpsNotAssigned(response.data);
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        }).then(function () { return setLoadingNotAssigned(false); });
    }, [pageNotAssigned, pageSizeNotAssigned, filterStateNotAssigned]);
    useEffect(function () {
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
        setLoading(true);
        api.get('supports/helpFilters', config).then(function (response) {
            try {
                setFilterVal(response.data);
            }
            catch (e) {
                handleLogout();
            }
        });
        //creating the filter route 
        var urlTotalPagesAssignedToMe = "/supports/pendingHelpsAssignedToMeTotalPages?pagesize=" + pageSize;
        var urlPendingHelpsAssignedToMe = "/supports/pendingHelpsAssignedToMe?page=" + page + "&pagesize=" + pageSize;
        if (filterState) {
            for (var key in filterState) {
                var value = filterState[key];
                if (value != null && value != undefined && value != '') {
                    urlTotalPagesAssignedToMe += "&" + key + "=" + value;
                    urlPendingHelpsAssignedToMe += "&" + key + "=" + value;
                }
            }
        }
        api.get(urlTotalPagesAssignedToMe, config)
            .then(function (response) {
            setTotalPages(response.data);
            try {
                if (response.data > page) {
                    setHasNext(true);
                }
                else {
                    setHasNext(false);
                }
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        });
        api.get(urlPendingHelpsAssignedToMe, config)
            .then(function (response) {
            try {
                setHelps(response.data);
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        }).then(function () { return setLoading(false); });
    }, [page, pageSize, filterState]);
    // function to assign the help for yourself
    var assignToMe = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var urlTotalPages, urlPendingHelps, urlTotalPagesNotAssigned, urlPendingHelpsNotAssigned;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, api.patch("/supports/" + id + "/assignToMe", '', config)];
                case 1:
                    _a.sent();
                    urlTotalPages = "/supports/pendingHelpsAssignedToMeTotalPages?pagesize=" + pageSize;
                    urlPendingHelps = "/supports/pendingHelpsAssignedToMe?page=" + page + "&pagesize=" + pageSize;
                    urlTotalPagesNotAssigned = "/supports/pendingHelpsAssignedToTotalPages?pagesize=" + pageSizeNotAssigned;
                    urlPendingHelpsNotAssigned = "supports/pendingHelpsAssignedTo?page=" + pageNotAssigned + "&pagesize=" + pageSizeNotAssigned;
                    setLoading(true);
                    setLoadingNotAssigned(true);
                    return [4 /*yield*/, api.get(urlTotalPagesNotAssigned, config)
                            .then(function (response) {
                            setTotalPagesNotAssigned(response.data);
                            try {
                                if (response.data > pageNotAssigned) {
                                    setHasNextNotAssigned(true);
                                }
                                else {
                                    setHasNextNotAssigned(false);
                                }
                            }
                            catch (e) {
                                handleLogout();
                                history.push('/');
                            }
                        })];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, api.get(urlPendingHelpsNotAssigned, config)
                            .then(function (response) {
                            try {
                                setHelpsNotAssigned(response.data);
                            }
                            catch (e) {
                                handleLogout();
                                history.push('/');
                            }
                        }).then(function () { return setLoadingNotAssigned(false); })];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, api.get(urlTotalPages, config)
                            .then(function (response) {
                            setTotalPages(response.data);
                            try {
                                if (response.data > page) {
                                    setHasNext(true);
                                }
                                else {
                                    setHasNext(false);
                                }
                            }
                            catch (e) {
                                handleLogout();
                                history.push('/');
                            }
                        })];
                case 4:
                    _a.sent();
                    return [4 /*yield*/, api.get(urlPendingHelps, config)
                            .then(function (response) {
                            try {
                                setHelps(response.data);
                            }
                            catch (e) {
                                handleLogout();
                                history.push('/');
                            }
                        }).then(function () { return setLoading(false); })];
                case 5:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var lineBtnsNotAssigned = [
        {
            name: 'Assinar',
            icon: _jsx(BsPen, {}, void 0),
            fn: function (id) {
                assignToMe(id);
            }
        }
    ];
    var lineBtnDetailNotAssigned = {
        name: 'Detalhes',
        icon: _jsx(BsSearch, {}, void 0),
        fn: function (id) {
            //history.push(`/profile/${id}`)
            //Abre o help em uma nova aba.
            window.open("/profileNewPage/" + id, "_blank");
            //Marca o help como visualizado.
            var newhelps = __spreadArray([], helpsNotAssigned);
            newhelps.map(function (item) {
                if (item.id === id) {
                    item.viewed = true;
                }
            });
            setHelpsNotAssigned(newhelps);
        }
    };
    lineBtnsNotAssigned.push(lineBtnDetailNotAssigned);
    var lineBtns = [];
    var lineBtnDetail = {
        name: 'Detalhes',
        icon: _jsx(BsSearch, {}, void 0),
        fn: function (id) {
            //history.push(`/profile/${id}`)
            //Abre o help em uma nova aba.
            window.open("/profileNewPage/" + id, "_blank");
            //Marca o help como visualizado.
            var newhelps = __spreadArray([], helps);
            newhelps.map(function (item) {
                if (item.id === id) {
                    item.viewed = true;
                }
            });
            setHelps(newhelps);
        }
    };
    lineBtns.push(lineBtnDetail);
    // buttons for table menu
    var menuButtons = [
        {
            id: 3,
            icon: _jsx(GrFilter, {}, void 0),
            title: 'Filtrar',
            fn: function () {
                if (filter === false) {
                    setFilter(true);
                }
                else {
                    setFilter(false);
                }
            }
        },
        {
            id: 2,
            icon: _jsx(FiPlus, {}, void 0),
            title: 'Novo Chamado',
            fn: function () {
                history.push('/create');
            }
        }
    ];
    // buttons for table menu
    var menuButtonsNotAssigned = [
        //POR ENQUANTO NÃO SERÁ NECESSÁRIO TER FILTRO DE PESQUISA DOS NOVOS CHAMADOS (NÃO ASSINADOS PARA ALGUÉM)
        //{
        //    id: 3,
        //    icon: <GrFilter />,
        //    title: 'Filtrar',
        //    fn: () => {
        //        if (filterNotAssigned === false) {
        //            setFilterNotAssigned(true)
        //        }
        //        else {
        //            setFilterNotAssigned(false)
        //        }
        //    }
        //},
        {
            id: 2,
            icon: _jsx(FiPlus, {}, void 0),
            title: 'Novo Chamado',
            fn: function () {
                history.push('/create');
            }
        }
    ];
    var closeFilter = function () {
        setFilter(false);
    };
    var closeFilterNotAssigned = function () {
        setFilterNotAssigned(false);
    };
    function pages() {
        setPage(1);
    }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: styles.container }, { children: _jsxs("div", __assign({ className: styles.containerBlock }, { children: [_jsxs("div", __assign({ className: styles.containerAssignedToMe }, { children: [filter &&
                                _jsx("div", __assign({ className: 'm-4' }, { children: _jsx(Filter, { page: pages, closer: closeFilter, data: filterVal, stateOfFilter: setFilterState }, void 0) }), void 0), _jsx(Table, { setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, setPage: setPage, data: helps, totalPAges: totalPages, headerButtons: menuButtons, lineButton: lineBtns, title: 'Meus Chamados', exporter: true, loading: loading, columns: [
                                    { label: 'ID', key: 'id', eventClick: lineBtnDetail.fn },
                                    { label: 'TFS', key: 'tfsWorkItem' },
                                    { label: 'Aplicação', key: 'applicationName' },
                                    { label: 'Descrição', key: 'especification', maxLenghtToShowSeeMore: 70 },
                                    { label: 'Status', key: 'statusName' },
                                    { label: 'Empresa', key: 'companyName' },
                                    { label: 'Usuário', key: 'requestUserName' },
                                    { label: 'Data Criação', key: 'creationDateWithTime' },
                                    { label: 'Último Comentário', key: 'lastCommentWithTime' },
                                ] }, void 0)] }), void 0), _jsxs("div", __assign({ className: styles.containerNotAssigned }, { children: [filterNotAssigned &&
                                _jsx("div", __assign({ className: 'm-4' }, { children: _jsx(Filter, { page: pages, closer: closeFilterNotAssigned, data: filterValNotAssigned, stateOfFilter: setFilterStateNotAssigned }, void 0) }), void 0), _jsx(Table, { setSize: setPageSizeNotAssigned, size: pageSizeNotAssigned, hasNext: hasNextNotAssigned, page: pageNotAssigned, setPage: setPageNotAssigned, data: helpsNotAssigned, totalPAges: totalPagesNotAssigned, headerButtons: menuButtonsNotAssigned, lineButton: lineBtnsNotAssigned, exporter: true, loading: loadingNotAssigned, title: 'Novos Chamados', columns: [
                                    { label: 'ID', key: 'id', eventClick: lineBtnDetailNotAssigned.fn },
                                    { label: 'Aplicação', key: 'applicationName' },
                                    { label: 'Descrição', key: 'especification', maxLenghtToShowSeeMore: 60 },
                                    { label: 'Status', key: 'statusName' },
                                    { label: 'Empresa', key: 'companyName' },
                                    { label: 'Usuário', key: 'requestUserName' },
                                    { label: 'Data Criação', key: 'creationDateWithTime' },
                                    { label: 'Último Comentário', key: 'lastCommentWithTime' },
                                ] }, void 0)] }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default Dashboard;
