var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import './styles.css';
var Select = function (props) {
    var values = props.values.options;
    var label = props.values.label;
    var valueMember = props.valueMember;
    var displayMember = props.displayMember;
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "selectContainer  " }, { children: [_jsxs("label", __assign({ className: "selectLabel " }, { children: [label, ":"] }), void 0), _jsxs("select", __assign({ onChange: props.onChange, name: props.name }, (props.value != undefined ? { value: props.value || '' } : {}), { disabled: props.disabled, className: "selectInput " + (props.resize ? 'selectInputWidthResized' : 'selectInputWidthDefault') }, { children: [props.firstOption ?
                            _jsx(_Fragment, { children: _jsx("option", __assign({ value: '' || 0, hidden: true, className: 'text-gray-600' }, { children: props.firstOption }), void 0) }, void 0)
                            :
                                _jsx("option", __assign({ value: '', hidden: props.notNull, className: 'text-gray-600' }, { children: "Selecione..." }), void 0), values.map(function (item, index) { return (_jsx("option", __assign({ value: item[valueMember], className: 'text-gray-600' }, { children: item[displayMember] }), index)); })] }), void 0)] }), void 0) }, void 0));
};
export default Select;
