var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MdCheckCircle, MdError } from 'react-icons/md';
import './styles.css';
var FileList = function (_a) {
    var images = _a.images, deleteButton = _a.deleteButton;
    return (_jsx("ul", __assign({ className: 'FileListUl' }, { children: images.map(function (image, index) { return (_jsxs("li", __assign({ className: 'FileListLi' }, { children: [_jsxs("div", __assign({ className: 'fileinfo flex items-center' }, { children: [_jsx("div", { style: {
                                backgroundImage: "url( " + image.preview + "  )",
                                height: '36px',
                                width: '36px',
                                borderRadius: '5px',
                                backgroundPosition: '50% 50%',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                            } }, void 0), _jsxs("div", __assign({ className: 'fileText flex flex-col ml-1' }, { children: [_jsx("strong", { children: image.name }, void 0), _jsxs("span", __assign({ className: ' text-gray-300 mt-2' }, { children: [image.readableSize, _jsx("button", __assign({ className: 'ml-1 border-0 bg-transparent text-red-600 font-semibold', onClick: function () { return deleteButton(image.id); } }, { children: "Excluir" }), void 0)] }), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: '.fileError flex mt-2 items-center' }, { children: image.error ?
                        _jsx(MdError, { title: 'Arquivo muito grande', className: 'error mr-4 text-sm text-red-600' }, void 0)
                        :
                            _jsx(MdCheckCircle, { className: 'success mr-4 text-sm text-blue-200' }, void 0) }), void 0)] }), index)); }) }), void 0));
};
export default FileList;
