var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { BsSearch, BsTrash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { MdAutorenew } from "react-icons/md";
import { useHistory } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import api from "../../../services/api";
import ModalLicenses from "../../Modals/ModalLicenses";
import ModalLicensesRenov from "../../Modals/ModalLicensesRenov";
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { FiDownload } from "react-icons/fi";
import ModalConfirmation from "../../Modals/ModalConfirmation";
import { Table } from 'template/lib';
import styles from './styles.module.scss';
import apiUrl from '../../../services/apiUrl';
var LicensessTab = function (props) {
    var _a = useState(0), idtoChange = _a[0], setIdToChange = _a[1];
    var _b = useState(false), refresh = _b[0], setRefresh = _b[1];
    var _c = useState(false), refreshRenov = _c[0], setRefreshRenov = _c[1];
    var _d = useState([]), details = _d[0], setDetails = _d[1];
    var _e = useState(1), pageDetails = _e[0], setPageDetails = _e[1];
    var _f = useState(10), pageSizeDetails = _f[0], setPageSizeDetails = _f[1];
    var _g = useState(false), hasNextDetails = _g[0], setHasNextDetails = _g[1];
    var _h = useState(1), totalPagesDetails = _h[0], setTotalPagesDetails = _h[1];
    var _j = useState(false), detailsTable = _j[0], setDetailsTable = _j[1];
    var _k = useState(true), loading = _k[0], setLoading = _k[1];
    var _l = useState(true), loadingDetails = _l[0], setLoadingDetails = _l[1];
    var _m = useState(''), titleOfLicense = _m[0], setTitleOfLicense = _m[1];
    var history = useHistory();
    var _o = useState(-1), save = _o[0], setSave = _o[1];
    var _p = useState(1), page = _p[0], setPage = _p[1];
    var _q = useState(10), pageSize = _q[0], setPageSize = _q[1];
    var _r = useState(false), hasNext = _r[0], setHasNext = _r[1];
    var _s = useState(1), totalPages = _s[0], setTotalPages = _s[1];
    var _t = useState([]), items = _t[0], setItems = _t[1];
    var _u = useState([]), licensesState = _u[0], setLicensesState = _u[1];
    var _v = useState(0), idLicense = _v[0], setIdLicense = _v[1];
    var _w = useState(), contentToChange = _w[0], setContentToChange = _w[1];
    var _x = useState(0), idLicenseRenov = _x[0], setidLicenseRenov = _x[1];
    var _y = useState(0), idtoChangeDetails = _y[0], setIdToChangeDetails = _y[1];
    var login = useAuth().login;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
            api.get("licenses/licensesByCompanyId?companyId=" + props.companyId, config).then(function (response) {
                setItems(response.data);
            }).then(function () { return setLoading(false); });
            if (refresh === true) {
                setRefresh(false);
            }
        }
        else {
            api.get("licenses/licensesByCompanyIdReadOnly?companyId=" + props.companyId, config).then(function (response) {
                setItems(response.data);
            }).then(function () { return setLoading(false); });
            if (refresh === true) {
                setRefresh(false);
            }
        }
    }, [props.companyId, refresh, page, pageSize]);
    useEffect(function () {
        setDetailsTable(false);
    }, [props.companyId]);
    useEffect(function () {
        if (refreshRenov === true) {
            api.get("licenses/" + idLicense + "/licenseRenovations", config).then(function (response) {
                if (idtoChange > 0) {
                    setIdLicense(idtoChange);
                }
                setDetails(response.data.renovations);
                setRefreshRenov(false);
                setRefresh(true);
            });
        }
    }, [refreshRenov, pageSizeDetails]);
    useEffect(function () {
        if (idtoChange == 0) {
            return;
        }
        api.put("licenses/" + idtoChange, licensesState, config).then(function (response) { });
    }, [licensesState, idtoChange]);
    useEffect(function () {
        if (idtoChangeDetails == 0) {
            return;
        }
        api.put("licenses/licenseRenovation/" + idtoChangeDetails, contentToChange, config).then(function (response) { });
    }, [contentToChange, idtoChangeDetails]);
    var lineBtnsWithoutAssginTo;
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
        lineBtnsWithoutAssginTo = [
            {
                name: 'Detalhes',
                icon: _jsx(BsSearch, { className: '' }, void 0),
                fn: function (id) {
                    api.get("licenses/" + id + "/licenseRenovations", config).then(function (response) {
                        setIdLicense(id);
                        setDetails(response.data.renovations);
                        setTitleOfLicense(response.data.renovationTitle);
                        setDetailsTable(true);
                    }).then(function () { return setLoadingDetails(false); });
                }
            },
            {
                name: 'Excluir',
                icon: _jsx(BsTrash, { size: 14, className: '' }, void 0),
                fn: function (id) {
                    setidLicenseRenov(id);
                    handleOpenModalConfirmationLicense();
                }
            },
            {
                name: 'Download Instalador',
                icon: _jsx(FiDownload, { size: 16 }, void 0),
                fn: function (id) {
                    window.open(apiUrl + "licenses/" + id + "/download-installer", "_blank");
                },
                hideWithCondition: true
            }
        ];
    }
    else {
        lineBtnsWithoutAssginTo = [
            {
                name: 'Detalhes',
                icon: _jsx(BsSearch, { className: '' }, void 0),
                fn: function (id) {
                    api.get("licenses/" + id + "/licenseRenovations", config).then(function (response) {
                        setIdLicense(id);
                        setDetails(response.data.renovations);
                        setTitleOfLicense(response.data.renovationTitle);
                        setDetailsTable(true);
                    }).then(function () { return setLoadingDetails(false); });
                }
            },
            {
                name: 'Download Instalador',
                icon: _jsx(FiDownload, { size: 16 }, void 0),
                fn: function (id) {
                    window.open(apiUrl + "licenses/" + id + "/download-installer", "_blank");
                },
                hideWithCondition: true
            }
        ];
    }
    var lineBtnsWithoutAssginToDetails;
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
        lineBtnsWithoutAssginToDetails = [
            {
                name: 'Excluir',
                icon: _jsx(BsTrash, { size: 14, className: '' }, void 0),
                fn: function (id) {
                    setidLicenseRenov(id);
                    handleOpenModalConfirmationLicenseRenov();
                }
            },
            {
                name: 'Resetar',
                icon: _jsx(MdAutorenew, { size: 16, className: 'text-sm' }, void 0),
                fn: function (id) {
                    setSave(id);
                }
            },
        ];
    }
    else {
        lineBtnsWithoutAssginToDetails = [
            {
                name: 'Resetar',
                icon: _jsx(MdAutorenew, { size: 16, className: 'text-sm' }, void 0),
                fn: function (id) {
                    setSave(id);
                }
            },
        ];
    }
    var lineBtnsWithoutAssginToDetailsSave = [
        {
            name: 'Confirmar',
            icon: _jsxs("div", __assign({ className: styles.divConfirmReset }, { children: [_jsx(GiConfirmed, { size: 16 }, void 0), " "] }), void 0),
            id: save,
            fn: function (id) {
                api.patch("licenses/" + idLicense + "/licenseRenovations/" + id + "/reset", '', config).then(function (response) { return console.log(response.data); }).then(function () { return setSave(0); }).then(function () {
                    api.get("licenses/" + idLicense + "/licenseRenovations", config).then(function (response) {
                        setDetails(response.data.renovations);
                    });
                });
            }
        },
        {
            name: 'Cancelar',
            icon: _jsxs("div", __assign({ className: styles.divCancelReset }, { children: [" ", _jsx(GiCancel, { size: 16 }, void 0), " "] }), void 0),
            id: save,
            fn: function (id) {
                setSave(-1);
            }
        }
    ];
    var menuBtn = [];
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
        menuBtn = [
            {
                fn: function () { return handleOpenModalLicenses(); },
                icon: _jsx(FiPlus, { size: 16 }, void 0),
                id: 1,
                title: 'Nova Licença'
            }
        ];
    }
    var menuBtnRenov = [];
    if ((login === null || login === void 0 ? void 0 : login.role) === 'Administrator') {
        menuBtnRenov = [
            {
                fn: function () { return handleOpenModalLicensesRenov(); },
                icon: _jsx(FiPlus, { size: 16 }, void 0),
                id: 1,
                title: 'Nova Licença'
            }
        ];
    }
    var _z = useState(false), modalIsOpenLicenses = _z[0], setIsOpenLicenses = _z[1];
    function handleOpenModalLicenses() {
        setIsOpenLicenses(true);
    }
    function handlecloseModalLicenses() {
        setIsOpenLicenses(false);
    }
    var _0 = useState(false), modalIsOpenLicensesRenov = _0[0], setIsOpenLicensesRenov = _0[1];
    function handleOpenModalLicensesRenov() {
        setIsOpenLicensesRenov(true);
    }
    function handlecloseModalLicensesRenov() {
        setIsOpenLicensesRenov(false);
    }
    var _1 = useState(false), modalIsOpenConfirmationLincese = _1[0], setIsOpenConfirmationLicense = _1[1];
    function handleOpenModalConfirmationLicense() {
        setIsOpenConfirmationLicense(true);
    }
    function handlecloseModalConfirmationLicense() {
        setIsOpenConfirmationLicense(false);
    }
    var _2 = useState(false), modalIsOpenConfirmationLinceseRenov = _2[0], setIsOpenConfirmationLicenseRenov = _2[1];
    function handleOpenModalConfirmationLicenseRenov() {
        setIsOpenConfirmationLicenseRenov(true);
    }
    function handlecloseModalConfirmationLicenseRenov() {
        setIsOpenConfirmationLicenseRenov(false);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: "" + (detailsTable ? styles.fisrtContentBoxBreak : styles.fisrtContentBox) }, { children: (login === null || login === void 0 ? void 0 : login.role) === 'Administrator'
                            ?
                                _jsx(Table, { setSize: setPageSize, hasNext: hasNext, page: page, setPage: setPage, highLightLine: true, data: items, refresh: setRefresh, setData: setItems, headerButtons: menuBtn, lineButton: lineBtnsWithoutAssginTo, changeExistentContent: setLicensesState, totalPAges: totalPages, title: 'Licen\u00E7as', loading: loading, editable: true, setIdToChange: setIdToChange, columns: [
                                        { label: 'Produto', key: 'select_products' },
                                        { label: 'Quantidade', key: 'quantity' },
                                        { label: 'Valor', key: 'licenseFee' },
                                        { label: 'Status', key: 'select_status' },
                                        { label: 'Modo de Atualização', key: 'select_methods' },
                                        { label: 'Recorrência', key: 'select_recorrencies' },
                                        { label: 'Detalhes', key: 'details' }
                                    ] }, void 0)
                            :
                                _jsx(Table, { setSize: setPageSize, hasNext: hasNext, page: page, setPage: setPage, data: items, refresh: setRefresh, setData: setItems, highLightLine: true, headerButtons: menuBtn, lineButton: lineBtnsWithoutAssginTo, changeExistentContent: setLicensesState, totalPAges: totalPages, title: 'Licen\u00E7as', loading: loading, setIdToChange: setIdToChange, columns: [
                                        { label: 'Produto', key: 'product' },
                                        { label: 'Quantidade', key: 'quantity' },
                                        { label: 'Status', key: 'status' },
                                        { label: 'Modo de Atualização', key: 'updateMethod' },
                                        { label: 'Recorrência', key: 'recurrence' },
                                        { label: 'Detalhes', key: 'details' }
                                    ] }, void 0) }), void 0), detailsTable &&
                        _jsx("div", { className: 'h-0.5 w-full bg-gray-200' }, void 0), _jsx("div", __assign({ className: "" + (detailsTable && styles.detailsTable) }, { children: detailsTable &&
                            _jsx(_Fragment, { children: _jsx(Table, { setSize: setPageSizeDetails, size: pageSizeDetails, hasNext: hasNextDetails, page: pageDetails, setPage: setPageDetails, data: details, headerButtons: menuBtnRenov, totalPAges: totalPagesDetails, loading: loadingDetails, changeExistentContent: setContentToChange, setData: setDetails, setIdToChange: setIdToChangeDetails, lineButton: lineBtnsWithoutAssginToDetails, lineButtonForEach: lineBtnsWithoutAssginToDetailsSave, editable: (login === null || login === void 0 ? void 0 : login.role) === 'Administrator', title: titleOfLicense, columns: [
                                        { label: 'Data Renovação', key: 'dateRenovation' },
                                        { label: 'Data Expiração', key: 'dateExpiration' },
                                        { label: 'Número da Licença', key: 'licenseNumber' },
                                        { label: 'Última Validação', key: 'dateLastValidation' },
                                        { label: 'Detalhes', key: 'renovationDetails' },
                                        { label: 'Número Serial', key: 'hdSerialNumber' },
                                        { label: 'Usuário do Domínio', key: 'userDomain' },
                                        { label: 'Versão Instalada', key: 'softwareLicence' }
                                    ] }, void 0) }, void 0) }), void 0)] }), void 0), _jsx(ModalLicenses, { companyId: props.companyId, setRefresh: setRefresh, modalIsOpenLicenses: modalIsOpenLicenses, handleOpenModalLicenses: handleOpenModalLicenses, handlecloseModalLicenses: handlecloseModalLicenses }, void 0), _jsx(ModalLicensesRenov, { LicenseId: idLicense, companyId: props.companyId, setRefresh: setRefreshRenov, modalIsOpenLicensesRenov: modalIsOpenLicensesRenov, handleOpenModalLicensesRenov: handleOpenModalLicensesRenov, handlecloseModalLicensesRenov: handlecloseModalLicensesRenov }, void 0), _jsx(ModalConfirmation, { setState: setDetailsTable, method: 'delete', url: "licenses/" + idLicenseRenov, title: 'essa licença', setRefresh: setRefresh, modalIsOpenConfirmation: modalIsOpenConfirmationLincese, handleOpenModalConfirmation: handleOpenModalConfirmationLicense, handlecloseModalConfirmation: handlecloseModalConfirmationLicense }, void 0), _jsx(ModalConfirmation, { method: 'delete', url: "licenses/licenseRenovation/" + idLicenseRenov, title: 'essa renovação de licença', setRefresh: setRefreshRenov, modalIsOpenConfirmation: modalIsOpenConfirmationLinceseRenov, handleOpenModalConfirmation: handleOpenModalConfirmationLicenseRenov, handlecloseModalConfirmation: handlecloseModalConfirmationLicenseRenov }, void 0)] }, void 0));
};
export default LicensessTab;
