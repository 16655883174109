var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import useAuth from "../../../hooks/useAuth";
import api from "../../../services/api";
import ModalUsers from "../../Modals/ModalUsers";
import { Table } from 'template/lib';
import styles from './styles.module.scss';
var UsersTab = function (props) {
    var _a = useState(0), idtoChange = _a[0], setIdToChange = _a[1];
    var _b = useState(1), page = _b[0], setPage = _b[1];
    var _c = useState(15), pageSize = _c[0], setPageSize = _c[1];
    var _d = useState(false), hasNext = _d[0], setHasNext = _d[1];
    var _e = useState(0), totalPages = _e[0], setTotalPages = _e[1];
    var _f = useState([]), items = _f[0], setItems = _f[1];
    var _g = useState([]), userState = _g[0], setUserState = _g[1];
    var _h = useState(false), refresh = _h[0], setRefresh = _h[1];
    var _j = useState(true), loading = _j[0], setLoading = _j[1];
    var login = useAuth().login;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        api.get("/companies/" + props.companyId + "/usersTotalPages?pageSize=" + pageSize, config)
            .then(function (response) {
            setTotalPages(response.data);
            if (response.data > page) {
                setHasNext(true);
            }
            else {
                setHasNext(false);
            }
        });
        api.get("/companies/" + props.companyId + "/users?page=" + page + "&pageSize=" + pageSize, config).then(function (response) {
            setItems(response.data);
        }).then(function () { return setLoading(false); });
        if (refresh === true) {
            setRefresh(false);
        }
    }, [props.companyId, refresh, page, pageSize]);
    useEffect(function () {
        if (idtoChange == 0) {
            return;
        }
        api.put("/companies/" + props.companyId + "/users/" + idtoChange, userState, config).then(function (response) {
        });
    }, [userState, idtoChange]);
    var _k = useState(false), modalIsOpenUsers = _k[0], setIsOpenUsers = _k[1];
    function handleOpenModalUsers() {
        setIsOpenUsers(true);
    }
    function handlecloseModalUsers() {
        setIsOpenUsers(false);
    }
    var menuBtn = [
        {
            fn: function () { return handleOpenModalUsers(); },
            icon: _jsx(FiPlus, { size: 16 }, void 0),
            id: 1,
            title: 'Novo Usuário'
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.container }, { children: _jsx(Table, { setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, setPage: setPage, data: items, setData: setItems, changeExistentContent: setUserState, totalPAges: totalPages, headerButtons: menuBtn, refresh: setRefresh, loading: loading, title: 'Usu\u00E1rios', setIdToChange: setIdToChange, editable: true, columns: [
                        { label: 'Id', key: 'id' },
                        { label: 'Data Cadastro', key: 'dateRegistration' },
                        { label: 'Status', key: 'select_status' },
                        { label: 'Tipo', key: 'select_role' },
                        { label: 'Nome', key: 'name' },
                        { label: 'Email', key: 'email' },
                        { label: 'Celular', key: 'cell' },
                        { label: 'Telefone', key: 'phone' },
                        { label: 'Função', key: 'function' },
                        { label: 'Cargo', key: 'position' },
                    ] }, void 0) }), void 0), _jsx(ModalUsers, { setRefresh: setRefresh, companyId: props.companyId, modalIsOpenUsers: modalIsOpenUsers, handleOpenModalUsers: handleOpenModalUsers, handlecloseModalUsers: handlecloseModalUsers }, void 0)] }, void 0));
};
export default UsersTab;
