var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import './styles.css';
var DropContainer = function (props) {
    var borderMEssege;
    if (props.isDragActive) {
        borderMEssege = 'border-green';
    }
    if (props.isDragReject) {
        borderMEssege = 'border-red';
    }
    if (props.fileRejections.length > 0 && props.isDragActive) {
        borderMEssege = 'border-red';
    }
    return (_jsx("div", __assign({}, props.rootProps(), { className: "borderUploader " + borderMEssege }, { children: props.children }), void 0));
};
export default DropContainer;
