var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import useAuth from "../../../hooks/useAuth";
import api from "../../../services/api";
import ModalOtherInfo from "../../Modals/ModalOtherInfo";
import { Table } from 'template/lib';
import styles from './styles.module.scss';
var OtherInfosTab = function (props) {
    var _a = useState(0), idtoChange = _a[0], setIdToChange = _a[1];
    var _b = useState(1), page = _b[0], setPage = _b[1];
    var _c = useState(10), pageSize = _c[0], setPageSize = _c[1];
    var _d = useState(false), hasNext = _d[0], setHasNext = _d[1];
    var _e = useState(1), totalPages = _e[0], setTotalPages = _e[1];
    var _f = useState([]), items = _f[0], setItems = _f[1];
    var _g = useState([]), otherInfoState = _g[0], setOtherInfoState = _g[1];
    var _h = useState(false), refresh = _h[0], setRefresh = _h[1];
    var _j = useState(true), loading = _j[0], setLoading = _j[1];
    var login = useAuth().login;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        //api.get(`GetOtherInfo?companyId=${props.companyId}`, config).then(response => {
        api.get("companies/" + props.companyId + "/otherInfos", config).then(function (response) {
            setItems(response.data);
        }).then(function () { return setLoading(false); }); /////////////
        if (refresh === true) {
            setRefresh(false);
        }
    }, [props.companyId, refresh, page, pageSize]);
    useEffect(function () {
        if (idtoChange == 0) {
            return;
        }
        //api.put(`changeAddInfo?id=${idtoChange}`, otherInfoState, config).then(response => {
        api.put("companies/" + props.companyId + "/otherInfos/" + idtoChange, otherInfoState, config).then(function (response) {
        });
    }, [otherInfoState, idtoChange]);
    var _k = useState(false), modalIsOpenOtherInfo = _k[0], setIsOpenOtherInfo = _k[1];
    function handleOpenModalOtherInfo() {
        setIsOpenOtherInfo(true);
    }
    function handlecloseModalOtherInfo() {
        setIsOpenOtherInfo(false);
    }
    var menuBtn = [
        {
            fn: function () { return handleOpenModalOtherInfo(); },
            icon: _jsx(FiPlus, { size: 16 }, void 0),
            id: 1,
            title: 'Nova Informação'
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.container }, { children: _jsx(Table, { setSize: setPageSize, hasNext: hasNext, page: page, setPage: setPage, data: items, setData: setItems, changeExistentContent: setOtherInfoState, totalPAges: totalPages, refresh: setRefresh, headerButtons: menuBtn, title: 'Outras informa\u00E7\u00F5es', setIdToChange: setIdToChange, editable: true, loading: loading, columns: [
                        { label: 'Tipo', key: 'type' },
                        { label: 'Informações', key: 'info' },
                        { label: 'Detalhes', key: 'details' },
                    ] }, void 0) }), void 0), _jsx(ModalOtherInfo, { companyId: props.companyId, setRefresh: setRefresh, modalIsOpenOtherInfo: modalIsOpenOtherInfo, handleOpenModalOtherInfo: handleOpenModalOtherInfo, handlecloseModalOtherInfo: handlecloseModalOtherInfo }, void 0)] }, void 0));
};
export default OtherInfosTab;
