var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from "react";
import './styles.css';
var TabContent = function (props) {
    var _a = useState(1), openTab = _a[0], setOpenTab = _a[1];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "tabsContainer  w-full" }, { children: [_jsx("ul", __assign({ className: "tabsUl flex flex-row text-center mt-2 ", role: "tablist" }, { children: props.contents.map(function (item, index) { return (_jsx("li", __assign({ className: "tabsLi mb-0 last:mr-0 w-24" }, { children: _jsx("a", __assign({ style: { fontSize: '0.8rem' }, className: "tabsTab font-bold  px-2 py-1 rounded-t-lg block leading-normal " +
                                (openTab === (index + 1)
                                    ? "tabsActive text-gray-800  bg-gray-200 border border-b-0 "
                                    : "tabsDisabled text-gray-400 bg-white"), onClick: function (e) {
                                e.preventDefault();
                                setOpenTab(index + 1);
                            }, "data-toggle": "tab", href: "#link" + (index + 1), role: "tablist" }, { children: item.name }), void 0) }), index)); }) }), void 0), _jsx("div", { className: "tabsLine w-full h-0.5 bg-gray-200 rounded-t-lg " }, void 0), props.contents.map(function (item, index) { return (_jsx("div", __assign({ className: "tabschildContent " + (openTab === (index + 1) ? "block" : "hidden"), id: "link" + (index + 1) }, { children: item.ComponentVal }), index + 1)); })] }), void 0) }, void 0));
};
export default TabContent;
