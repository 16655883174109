var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { RiFileExcel2Line } from 'react-icons/ri';
import Button from '../Button';
import Alert from '../Alert';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import CurrencyInput from 'react-currency-input-field';
import './styles.css';
var override = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f;\n"], ["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f;\n"])));
var Table = function (props) {
    var _a = useState(), line = _a[0], setLine = _a[1];
    var _b = useState([]), showComments = _b[0], setShowComments = _b[1];
    var _c = useState(-1), showBtnEdit = _c[0], setShowBtnEdit = _c[1];
    function changeItems(e, line, position) {
        setShowBtnEdit(line);
        var valueInsideTable = __spreadArray([], props.data);
        valueInsideTable[line][position] = e.target.value;
        props.setData(valueInsideTable);
        props.setIdToChange(0);
    }
    function changeItemsMonay(e, line, position) {
        setShowBtnEdit(line);
        var valueInsideTable = __spreadArray([], props.data);
        var decimalValue = e.target.value.replace('R$', '').replace('.', '');
        valueInsideTable[line][position] = decimalValue;
        props.setData(valueInsideTable);
        props.setIdToChange(0);
    }
    function changeItemsSelect(e, line, position) {
        return __awaiter(this, void 0, void 0, function () {
            var valueInsideTable;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setShowBtnEdit(line);
                        valueInsideTable = __spreadArray([], props.data);
                        valueInsideTable[line][position].id = e.target.value.split(',')[0];
                        valueInsideTable[line][position].name = e.target.value.split(',')[1];
                        if (!(position === 'select_state')) return [3 /*break*/, 3];
                        return [4 /*yield*/, props.changeExistentContent(valueInsideTable[line])];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, props.setData(valueInsideTable)];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, props.setIdToChange(props.data[line].id)];
                    case 3: return [4 /*yield*/, props.setData(valueInsideTable)];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, props.setIdToChange(0)];
                }
            });
        });
    }
    var changeTable = function (index) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.changeExistentContent(props.data[index])];
                case 1:
                    _a.sent();
                    setShowBtnEdit(-1);
                    return [2 /*return*/, props.setIdToChange(props.data[index].id)];
            }
        });
    }); };
    var highLightLineAndLineFn = function (id, fn) {
        var _a;
        fn(id);
        (_a = props.lineButton) === null || _a === void 0 ? void 0 : _a.map(function (lineBtn) {
            return setLine(id);
        });
    };
    var selectedComments = function (val) {
        if (showComments.includes(val)) {
            var newComments = showComments;
            var comments = newComments.filter(function (item) { return item !== val; });
            setShowComments(comments);
        }
        else {
            setShowComments(__spreadArray(__spreadArray([], showComments), [val]));
        }
    };
    var data = props.data;
    var columns = props.columns;
    var page = props.page;
    var size = props.size;
    // fixing date
    columns.map(function (col) {
        if (col.maxLenghtToShowSeeMore == null || col.maxLenghtToShowSeeMore == undefined) {
            col.maxLenghtToShowSeeMore = 49;
        }
        data.map(function (dat) {
            if (col.key.substr(0, 4).includes('date') && dat[col.key]) {
                var fullDate = String(dat[col.key]).split('.')[0];
                var dateTime = fullDate.split('T').join(' / ');
                if (col.key.includes('dateLast') && dat[col.key]) {
                    dat[col.key] = dat[col.key];
                }
                else {
                    dat[col.key] = props.editable ? dateTime.split('/')[0].trim() : dateTime.split('/')[0];
                }
            }
            return data;
        });
        return data;
    });
    var pageBack = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(page > 1)) return [3 /*break*/, 4];
                    if (!!page) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.setPage(1)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, props.setPage(parseInt(String(page)) - 1)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var pageUp = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!props.hasNext) return [3 /*break*/, 4];
                    if (!!page) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.setPage(1)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, props.setPage(parseInt(String(page)) + 1)];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (props.loading) {
        return (_jsx("div", __assign({ className: 'loadingContainer flex h-full w-full items-center justify-center' }, { children: _jsx("div", __assign({ className: 'loadingContentflex row' }, { children: _jsx(ClipLoader, { css: override, loading: true, size: 16 }, void 0) }), void 0) }), void 0));
    }
    return (_jsxs(_Fragment, { children: [props.title &&
                _jsxs("div", __assign({ className: 'tableHeader' }, { children: [_jsxs("div", { children: [_jsxs("span", __assign({ className: 'tableHeaderTitle ' }, { children: [props.title, " - "] }), void 0), _jsxs("span", __assign({ className: 'tableHeaderLength ' }, { children: [data.length, " Registros "] }), void 0)] }, void 0), _jsx("div", { children: _jsxs("div", __assign({ className: 'tableHeaderButtons ' }, { children: [props.exporter &&
                                        _jsx(CSVLink, __assign({ className: 'tableHeaderButtonChild ', separator: ';', filename: "my-file.csv", data: data, headers: columns }, { children: _jsx(RiFileExcel2Line, { title: 'Exportar excel' }, void 0) }), void 0), props.headerButtons &&
                                        _jsx(_Fragment, { children: props.headerButtons.map(function (button) { return (_jsx("div", { children: _jsx("a", __assign({ className: 'tableHeaderButtonChild', title: button.title, onClick: button.fn }, { children: button.icon }), void 0) }, (button.id + 800))); }) }, void 0)] }), void 0) }, void 0)] }), void 0), _jsx("div", __assign({ className: 'tableContainer' }, { children: _jsxs("table", __assign({ className: 'tableContent  ' }, { children: [_jsx("thead", { children: _jsxs("tr", { children: [columns.map(function (item, index) { return (_jsx("th", __assign({ className: 'tableHead ' }, { children: item.label }), index + 900)); }), props.lineButton &&
                                        _jsx(_Fragment, { children: props.lineButton.map(function (linebtn, index) { return (_jsx("th", __assign({ className: 'tableHead' }, { children: linebtn.name }), index + 200)); }) }, void 0), props.lineButtonForEach &&
                                        _jsx(_Fragment, { children: props.lineButtonForEach.map(function (linebtn, index) { return (_jsx("th", { className: 'tableHead ' }, index)); }) }, void 0), props.editable &&
                                        _jsx("th", { className: ' tableHead' }, void 0)] }, void 0) }, void 0), _jsx("tbody", { children: props.data.map(function (content, index1) {
                                return (_jsxs("tr", __assign({ className: " " + (content.viewed !== undefined ? (!content.viewed ? 'bg-gray-300' : null) : null) + " " + (line === content.id ?
                                        'contendId' : null) }, { children: [!props.editable ?
                                            _jsx(_Fragment, { children: columns.map(function (column, index) { return (_jsx("td", __assign({ style: { fontSize: '0.85rem' }, className: (showComments.includes(content.id) ? 'wrap' : 'tdata') + " " + (content.viewed !== undefined ? (!content.viewed && 'font-bold') : null) + " " }, { children: column.eventClick ?
                                                        _jsx(_Fragment, { children: _jsx("span", __assign({ style: { cursor: 'pointer' }, onClick: function () {
                                                                    if (column.eventClick) {
                                                                        column.eventClick(content.id); // Só chama a função se ela estiver definida
                                                                    }
                                                                } }, { children: content.id }), void 0) }, void 0)
                                                        :
                                                            _jsx(_Fragment, { children: column.key.substr(0, 4).includes('date') && content[column.key] !== null ? column.key.includes('dateLast') ? new Intl.DateTimeFormat('pt-BR', {
                                                                    dateStyle: 'short', timeStyle: 'medium'
                                                                }).format(new Date(content[column.key])) : new Intl.DateTimeFormat('pt-BR', {}).format(new Date(content[column.key]))
                                                                    : _jsxs(_Fragment, { children: [!showComments.includes(content.id) ? column.label === 'Valor' ?
                                                                                new Intl.NumberFormat('pt-BR', {
                                                                                    style: 'currency',
                                                                                    currency: 'BRL'
                                                                                }).format(content[column.key]) : String(content[column.key]).substr(0, column.maxLenghtToShowSeeMore + 10) : String(content[column.key]), String((content[column.key])).length > column.maxLenghtToShowSeeMore && !showComments.includes(content.id) && _jsx("span", __assign({ onClick: function () { return selectedComments(content.id); }, className: 'morInfo' }, { children: "...ver mais" }), void 0), String((content[column.key])).length > column.maxLenghtToShowSeeMore && showComments.includes(content.id) && _jsx("span", __assign({ onClick: function () { return selectedComments(content.id); }, className: 'morInfo' }, { children: " ver menos" }), void 0)] }, void 0) }, void 0) }), index + 300)); }) }, void 0)
                                            :
                                                _jsx(_Fragment, { children: columns.map(function (column, index) { return (_jsx("td", __assign({ style: { fontSize: '0.75rem' }, className: " tdataEditable" }, { children: String(column.key).includes('select') ?
                                                            _jsxs("select", __assign({ onChange: function (e) { return changeItemsSelect(e, (index1), column.key); }, name: column.key, value: content[column.key], className: "tdataSelectInput " + (line === content.id ? 'contendId' : '') }, { children: [_jsx("option", __assign({ hidden: true }, { children: content[column.key].name }), void 0), content[column.key].select.map(function (item, index) { return (_jsx("option", __assign({ value: [item.id, item.name], className: 'text-gray-600' }, { children: item.name }), index)); })] }), void 0)
                                                            : String(content[column.key]).length < 40 ?
                                                                _jsxs(_Fragment, { children: [column.label === 'Valor' ?
                                                                            _jsx(CurrencyInput, { className: "tdataSelectInput " + (line === content.id ? 'contendId' : ''), name: column.key, prefix: "R$", placeholder: "Please enter a number", value: content[column.key], decimalsLimit: 2, onChange: function (e) { return changeItemsMonay(e, (index1), column.key); } }, void 0)
                                                                            :
                                                                                _jsx("input", { disabled: column.key === 'id', className: "tdataSelectInput " + (line === content.id ? 'contendId' : ''), name: column.key, onChange: function (e) { return changeItems(e, (index1), column.key); }, type: column.key.substr(0, 4).includes('date') ? (column.key.includes('dateLast') ? 'datetime-local' : 'date') : 'text', value: content[column.key] !== null ? content[column.key] : '' }, void 0), " "] }, void 0)
                                                                : _jsx("textarea", { disabled: column.key === 'id', className: "tdataSelectInput tdataTextArea " + (line === content.id ? 'contendId' : ''), name: column.key, onChange: function (e) { return changeItems(e, (index1), column.key); }, value: content[column.key] !== null ? content[column.key] : '' }, void 0) }), index + 100)); }) }, void 0), props.lineButton &&
                                            _jsx(_Fragment, { children: props.lineButton.map(function (lineBtn, index) { return (_jsx("td", __assign({ style: { fontSize: '0.70rem' }, className: lineBtn.hideWithCondition && content.hideButton ? 'lineButtonHidden' : 'lineButton', onClick: function () {
                                                        if (lineBtn.hideWithCondition && content.hideButton) {
                                                            null;
                                                        }
                                                        else {
                                                            if (!props.highLightLine) {
                                                                lineBtn === null || lineBtn === void 0 ? void 0 : lineBtn.fn(content.id);
                                                            }
                                                            else {
                                                                highLightLineAndLineFn(content.id, lineBtn.fn);
                                                            }
                                                        }
                                                    } }, { children: lineBtn.hideWithCondition && content.hideButton ? null : lineBtn.icon }), index + 400)); }) }, void 0), props.lineButtonForEach &&
                                            _jsx(_Fragment, { children: props.lineButtonForEach.map(function (lineBtn, index) { return (_jsx(_Fragment, { children: lineBtn.id === content.id ?
                                                        _jsx("td", __assign({ title: lineBtn.name, className: "lineButtonForEach ", onClick: function () { return lineBtn === null || lineBtn === void 0 ? void 0 : lineBtn.fn(content.id); } }, { children: lineBtn.icon }), index) :
                                                        _jsx("td", { className: "lineButtonForEach " }, index) }, void 0)); }) }, void 0), props.editable &&
                                            _jsx(_Fragment, { children: showBtnEdit === index1 ?
                                                    _jsx("td", __assign({ className: "editButton" }, { children: _jsx(Button, __assign({ lineBtn: true, onClick: function () { return changeTable(index1); } }, { children: "Salvar" }), void 0) }), void 0)
                                                    :
                                                        _jsx("td", { className: "editButton" }, void 0) }, void 0)] }), index1));
                            }) }, void 0)] }), void 0) }), void 0), !data.length ?
                _jsxs(Alert, __assign({ info: true }, { children: ["N\u00E3o h\u00E1 ", props.title, " at\u00E9 o momento."] }), void 0)
                :
                    _jsx(_Fragment, { children: size &&
                            _jsxs("div", __assign({ className: "tableFooterContent" }, { children: [_jsx("div", {}, void 0), _jsx("div", __assign({ className: 'tablepageChanger' }, { children: _jsxs(_Fragment, { children: [_jsx(Link, __assign({ to: "#", onClick: pageBack, className: "tablepageTransaction  " + (page === 1 ? 'cursor-not-allowed ' : 'tableHovered ') }, { children: "Anterior" }), void 0), _jsxs("span", __assign({ className: "curretPage " }, { children: [_jsx("input", { className: 'tableInputPageChanger', value: page, onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                                                switch (_a.label) {
                                                                    case 0: return [4 /*yield*/, props.setPage(e.target.value > props.totalPAges ? 1 : e.target.value)];
                                                                    case 1: return [2 /*return*/, _a.sent()];
                                                                }
                                                            }); }); } }, void 0), _jsx("span", __assign({ className: 'tableBotomSpan' }, { children: " de " }), void 0), " ", props.totalPAges] }), void 0), _jsx(Link, __assign({ onClick: pageUp, to: "#", className: "tablepageTransaction " + (props.hasNext === false ? 'cursor-not-allowed ' : 'tableHovered ') }, { children: "Pr\u00F3xima" }), void 0)] }, void 0) }), void 0), _jsxs("div", __assign({ className: "tableLinesPerPageContainer flex items-center px-4 py-2 mx-1 " }, { children: [_jsx("label", __assign({ className: '' }, { children: "Linhas por p\u00E1gina:" }), void 0), _jsx("input", { className: 'tableInputLinesPerPage block w-8 ml-1 px-1 bg-grey-lighter text-center text-grey-darker border border-grey-lighter rounded-sm', value: size, onChange: function (e) { return props.setSize(e.target.value); } }, void 0)] }), void 0)] }), void 0) }, void 0)] }, void 0));
};
export default Table;
var templateObject_1;
