var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Table } from 'template/lib';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import api from '../../services/api';
import ModalLibrary from "../Modals/ModalLibrary";
import ModalLibraryFTP from "../Modals/ModalLibraryFTP";
import { FiDownload, FiEdit3, FiPlus } from "react-icons/fi";
import styles from './styles.module.scss';
import apiUrl from '../../services/apiUrl';
var Libraries = function () {
    var _a = useState(0), idToChange = _a[0], setIdToChange = _a[1];
    var _b = useState(0), idLibrary = _b[0], setIdLibrary = _b[1];
    var _c = useState([]), libraryState = _c[0], setLibraryState = _c[1];
    var _d = useAuth(), login = _d.login, handleLogout = _d.handleLogout;
    var _e = useState([]), items = _e[0], setItems = _e[1];
    var _f = useState(1), page = _f[0], setPage = _f[1];
    var _g = useState(0), totalPages = _g[0], setTotalPages = _g[1];
    var _h = useState(true), loading = _h[0], setLoading = _h[1];
    var _j = useState(10), pageSize = _j[0], setPageSize = _j[1];
    var _k = useState(false), refresh = _k[0], setRefresh = _k[1];
    var _l = useState(false), hasNext = _l[0], setHasNext = _l[1];
    var setTitle = useTitle().setTitle;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    var history = useHistory();
    useEffect(function () {
        setTitle('Bibliotecas');
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
        api.get("/libraries/get-by-company-total-pages/" + (login === null || login === void 0 ? void 0 : login.companyId) + "?pageSize=" + pageSize, config)
            .then(function (response) {
            setTotalPages(response.data);
            if (response.data > page) {
                setHasNext(true);
            }
            else {
                setHasNext(false);
            }
        });
        api.get("/libraries/get-by-company/" + (login === null || login === void 0 ? void 0 : login.companyId) + "?page=" + page + "&pageSize=" + pageSize, config)
            .then(function (response) {
            try {
                setItems(response.data);
            }
            catch (e) {
                console.log(response.data);
            }
        }).then(function () { return setLoading(false); });
    }, [refresh, page, pageSize]);
    useEffect(function () {
        if (idToChange == 0) {
            return;
        }
        api.put("/libraries/" + idToChange, libraryState, config).then(function () {
            setRefresh(!refresh);
        });
    }, [libraryState, idToChange]);
    var _m = useState(false), modalIsOpenLibrary = _m[0], setModalIsOpenLibrary = _m[1];
    function handleOpenModalLibrary() {
        setModalIsOpenLibrary(true);
    }
    function handleCloseModalLibrary() {
        setModalIsOpenLibrary(false);
    }
    var _o = useState(false), modalIsOpenLibraryFTP = _o[0], setModalIsOpenLibraryFTP = _o[1];
    function handleOpenModalLibraryFTP() {
        setModalIsOpenLibraryFTP(true);
    }
    function handleCloseModalLibraryFTP() {
        setModalIsOpenLibraryFTP(false);
    }
    var lineBtnLibraryFTP = [
        {
            fn: function (id) {
                setIdLibrary(id);
                handleOpenModalLibraryFTP();
            },
            icon: _jsx(FiEdit3, { size: 16 }, void 0),
            name: 'Informações FTP'
        },
        {
            fn: function (id) {
                window.open(apiUrl + "libraries/" + id + "/download-library-installer", "_blank");
            },
            icon: _jsx(FiDownload, { size: 16 }, void 0),
            name: 'Download Instalador Biblioteca'
        }
    ];
    var menuBtn = [
        {
            fn: function () {
                handleOpenModalLibrary();
                setRefresh(!refresh);
            },
            icon: _jsx(FiPlus, { size: 16 }, void 0),
            id: 1,
            title: 'Nova Biblioteca'
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: styles.container }, { children: _jsx(Table, { setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, setPage: setPage, data: items, setData: setItems, changeExistentContent: setLibraryState, totalPAges: totalPages, loading: loading, title: 'Bibliotecas', setIdToChange: setIdToChange, refresh: setRefresh, editable: true, headerButtons: menuBtn, lineButton: lineBtnLibraryFTP, columns: [
                        { label: 'ID', key: 'id' },
                        { label: 'Nome', key: 'name' },
                        { label: 'Data de Compilação', key: 'dateCompilation' },
                        { label: 'Principal', key: 'select_main' },
                    ] }, void 0) }), void 0), _jsx(ModalLibrary, { refresh: refresh, setRefresh: setRefresh, modalIsOpenLibrary: modalIsOpenLibrary, handleOpenModalLibrary: handleOpenModalLibrary, handleCloseModalLibrary: handleCloseModalLibrary }, void 0), _jsx(ModalLibraryFTP, { libraryId: idLibrary, modalIsOpenLibraryFTP: modalIsOpenLibraryFTP, handleOpenModalLibraryFTP: handleOpenModalLibraryFTP, handleCloseModalLibraryFTP: handleCloseModalLibraryFTP }, void 0)] }, void 0));
};
export default Libraries;
