var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import * as Yup from 'yup';
import styles from './styles.module.scss';
import { Button, InputText } from 'template/lib';
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
var ModalBuyEnvelopes = function (props) {
    var history = useHistory();
    var _a = useAuth(), login = _a.login, handleLogout = _a.handleLogout;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
    }, []);
    useEffect(function () {
        if (props.modalIsOpenBuyEnvelopes) {
            resetFormValues();
        }
    }, [props.modalIsOpenBuyEnvelopes]);
    var buyEnvelopesSchema = Yup.object().shape({
        quantity: Yup.number()
            .min(1, 'Por favor, informe uma quantidade maior que 0.')
            .required('Por favor, informe uma quantidade.')
    });
    function resetFormValues() {
        formik.resetForm({
            values: {
                companyId: login === null || login === void 0 ? void 0 : login.companyId,
                quantity: ''
            },
        });
    }
    var formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            companyId: login === null || login === void 0 ? void 0 : login.companyId,
            quantity: ''
        },
        validationSchema: buyEnvelopesSchema,
        onSubmit: function (values, actions) { return __awaiter(void 0, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, api.post('/envelopes-management/buy', values, config).then(function () {
                                resetFormValues();
                                props.setRefresh(!props.refresh);
                                props.handleCloseModalBuyEnvelopes();
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        if (!login || Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
                            handleLogout();
                            history.push('/');
                        }
                        console.log(err_1);
                        alert('erro');
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); },
    });
    var columns = 1;
    return (_jsx("div", { children: _jsx(Modal, __assign({ isOpen: props.modalIsOpenBuyEnvelopes, shouldCloseOnOverlayClick: false, onRequestClose: props.handleCloseModalBuyEnvelopes, overlayClassName: 'react-modal-overly', className: 'react-modal-content', contentLabel: "Example Modal" }, { children: _jsxs("div", __assign({ className: '' }, { children: [_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("div", {}, void 0), _jsx("h2", __assign({ className: ' font-semibold border-b-2 text-lg' }, { children: "Comprar Envelopes" }), void 0), _jsx(AiOutlineClose, { title: 'Fechar', className: 'cursor-pointer text-sm', onClick: props.handleCloseModalBuyEnvelopes }, void 0)] }), void 0), _jsx("div", __assign({ className: styles.content }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit, className: 'mx-4 my-4' }, { children: [_jsx("div", __assign({ className: 'grid justify-items-start' }, { children: _jsxs("div", __assign({ className: "grid grid-cols-1 md:grid-cols-" + columns + " lg:grid-cols-" + columns + " my-2 justify-items-end" }, { children: [formik.errors.quantity && formik.touched.quantity && _jsx("i", __assign({ className: 'text-red-400 mr-5' }, { children: formik.errors.quantity }), void 0), _jsx(InputText, { onChange: formik.handleChange, name: 'quantity', label: 'Quantidade', inicialValue: formik.values.quantity }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: styles.flexButton }, { children: _jsx(Button, __assign({ type: 'submit', className: '' }, { children: "Salvar" }), void 0) }), void 0)] }), void 0) }), void 0), _jsx("span", __assign({ style: { fontSize: '12.5px' } }, { children: "**Antes de comprar, \u00E9 necess\u00E1rio j\u00E1 ter adquirido os envelopes com a Docusign**" }), void 0)] }), void 0) }), void 0) }, void 0));
};
export default ModalBuyEnvelopes;
