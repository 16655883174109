var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useEffect, useState } from "react";
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
var override = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f;\n"], ["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f;\n"])));
export var AuthContext = createContext({});
export function AuthContextProvider(props) {
    var _this = this;
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState(), login = _b[0], setLogIn = _b[1];
    useEffect(function () {
        var loadStorageData = function () { return __awaiter(_this, void 0, void 0, function () {
            var storegedtoken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, localStorage.getItem('token')];
                    case 1:
                        storegedtoken = _a.sent();
                        if (storegedtoken) {
                            handleSetLogin(storegedtoken);
                        }
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); };
        loadStorageData();
    }, []);
    var handleSetLogin = function (token) { return __awaiter(_this, void 0, void 0, function () {
        var payload, name, company, companyId, role, login_1, expiresIn, hasPluginMaker, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    payload = token.split(".")[1];
                    payload = atob(payload);
                    payload = JSON.parse(payload);
                    name = decodeURIComponent(escape(payload.UserName));
                    company = decodeURIComponent(escape(payload.CompanyName));
                    companyId = payload.CompanyId;
                    role = decodeURIComponent(escape(payload.role));
                    login_1 = decodeURIComponent(escape(payload.UserId));
                    expiresIn = payload.exp;
                    hasPluginMaker = payload.HasPluginMaker;
                    return [4 /*yield*/, setLogIn({
                            token: token,
                            name: name,
                            role: role,
                            company: company,
                            login: login_1,
                            expiresIn: expiresIn,
                            companyId: companyId,
                            hasPluginMaker: hasPluginMaker
                        })];
                case 1:
                    _a.sent();
                    if (!login_1) {
                        handleLogout();
                    }
                    localStorage.setItem('token', token);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    handleLogout();
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleLogout = function () {
        setLogIn(undefined);
        localStorage.removeItem('token');
    };
    if (loading) {
        return (_jsx("div", __assign({ className: 'flex h-full w-full items-center justify-center' }, { children: _jsx("div", __assign({ className: 'flex row' }, { children: _jsx(ClipLoader, { css: override, loading: loading, size: 16 }, void 0) }), void 0) }), void 0));
    }
    return (_jsx(AuthContext.Provider, __assign({ value: { login: login, handleSetLogin: handleSetLogin, handleLogout: handleLogout } }, { children: props.children }), void 0));
}
var templateObject_1;
