var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Table } from 'template/lib';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import api from '../../services/api';
import ModalBuyEnvelopes from "../Modals/ModalBuyEnvelopes";
import ModalTransferEnvelopes from "../Modals/ModalTransferEnvelopes";
import ModalConfirmation from "../Modals/ModalConfirmation";
import { FiPlus } from "react-icons/fi";
import { BsSearch, BsTrash } from "react-icons/bs";
import { FaUndo } from "react-icons/fa";
import { TbTransferIn } from "react-icons/tb";
import styles from './styles.module.scss';
var EnvelopesManagement = function () {
    //const [idToChange, setIdToChange] = useState(0)
    var _a = useAuth(), login = _a.login, handleLogout = _a.handleLogout;
    var _b = useState([]), items = _b[0], setItems = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(0), totalPages = _d[0], setTotalPages = _d[1];
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(10), pageSize = _f[0], setPageSize = _f[1];
    var _g = useState(false), refresh = _g[0], setRefresh = _g[1];
    var _h = useState(false), hasNext = _h[0], setHasNext = _h[1];
    var setTitle = useTitle().setTitle;
    var _j = useState(false), showTransfersTable = _j[0], setShowTransfersTable = _j[1];
    var _k = useState([]), transferredEnvelopes = _k[0], setTransferredEnvelopes = _k[1];
    var _l = useState(true), loadingTransferredEnvelopes = _l[0], setLoadingTransferredEnvelopes = _l[1];
    var _m = useState(1), pageDetails = _m[0], setPageDetails = _m[1];
    var _o = useState(10), pageSizeDetails = _o[0], setPageSizeDetails = _o[1];
    var _p = useState(false), hasNextDetails = _p[0], setHasNextDetails = _p[1];
    var _q = useState(1), totalPagesDetails = _q[0], setTotalPagesDetails = _q[1];
    var _r = useState(0), idPurchasedEnvelope = _r[0], setIdPurchasedEnvelope = _r[1];
    var _s = useState(0), idTransferredEnvelope = _s[0], setIdTransferredEnvelope = _s[1];
    var _t = useState(''), titleTransferredEnvelopes = _t[0], setTitleTransferredEnvelopes = _t[1];
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    var history = useHistory();
    useEffect(function () {
        setTitle('Gerenciamento de Envelopes');
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        if (!login) {
            history.push('/');
        }
        //api.get(`/envelope-management/EnvelopeManagerList`, config)
        //    .then(response => {
        //        setTotalPages(response.data)
        //        if (response.data > page) {
        //            setHasNext(true)
        //        }
        //        else {
        //            setHasNext(false)
        //        }
        //    })
        api.get("/envelopes-management/purchased", config)
            .then(function (response) {
            try {
                setItems(response.data);
            }
            catch (e) {
                console.log(response.data);
            }
        }).then(function () { return setLoading(false); });
        if (showTransfersTable && idPurchasedEnvelope > 0) {
            api.get("/envelopes-management/transferred-by-purchased-envelope/" + idPurchasedEnvelope, config)
                .then(function (response) {
                setTransferredEnvelopes(response.data);
            }).then(function () { return setLoadingTransferredEnvelopes(false); });
        }
    }, [refresh, page, pageSize]);
    var lineBtnsPurchasedEnvelope;
    lineBtnsPurchasedEnvelope = [
        {
            name: 'Consultar Transferências',
            icon: _jsx(BsSearch, { size: 12, className: '' }, void 0),
            fn: function (id) {
                api.get("/envelopes-management/transferred-by-purchased-envelope/" + id, config)
                    .then(function (response) {
                    setIdPurchasedEnvelope(id);
                    setTransferredEnvelopes(response.data);
                    setTitleTransferredEnvelopes("Transfer\u00EAncias - Envelope ID: " + id);
                    setShowTransfersTable(true);
                }).then(function () { return setLoadingTransferredEnvelopes(false); });
            }
        },
        {
            name: 'Excluir',
            icon: _jsx(BsTrash, { size: 14, className: '' }, void 0),
            fn: function (id) {
                setIdPurchasedEnvelope(id);
                handleOpenModalConfirmationDeleteEnvelope();
            }
        }
    ];
    var lineBtnsTransferredEnvelopes;
    lineBtnsTransferredEnvelopes = [
        {
            name: 'Estornar',
            icon: _jsx(FaUndo, { size: 14, className: '' }, void 0),
            fn: function (id) {
                setIdTransferredEnvelope(id);
                handleOpenModalConfirmationUndoTransfer();
            }
        }
    ];
    var _u = useState(false), modalIsOpenBuyEnvelopes = _u[0], setmodalIsOpenBuyEnvelopes = _u[1];
    function handleOpenModalBuyEnvelopes() {
        setmodalIsOpenBuyEnvelopes(true);
    }
    function handleCloseModalBuyEnvelopes() {
        setmodalIsOpenBuyEnvelopes(false);
    }
    var _v = useState(false), isOpenModalTransferEnvelopes = _v[0], setIsOpenModalTransferEnvelopes = _v[1];
    function handleOpenModalTransferEnvelopes() {
        setIsOpenModalTransferEnvelopes(true);
    }
    function handleCloseModalTransferEnvelopes() {
        setIsOpenModalTransferEnvelopes(false);
    }
    var _w = useState(false), isOpenModalConfirmationDeleteEnvelope = _w[0], setIsOpenModalConfirmationDeleteEnvelope = _w[1];
    function handleOpenModalConfirmationDeleteEnvelope() {
        setIsOpenModalConfirmationDeleteEnvelope(true);
    }
    function handleCloseModalConfirmationDeleteEnvelope() {
        setIsOpenModalConfirmationDeleteEnvelope(false);
    }
    var _x = useState(false), isOpenModalConfirmationUndoTransfer = _x[0], setIsOpenModalConfirmationUndoTransfer = _x[1];
    function handleOpenModalConfirmationUndoTransfer() {
        setIsOpenModalConfirmationUndoTransfer(true);
    }
    function handleCloseModalConfirmationUndoTransfer() {
        setIsOpenModalConfirmationUndoTransfer(false);
    }
    var menuBtn = [
        {
            fn: function () {
                handleOpenModalBuyEnvelopes();
                setRefresh(!refresh);
            },
            icon: _jsx(FiPlus, { size: 20 }, void 0),
            id: 1,
            title: 'Comprar Envelopes'
        },
        {
            fn: function () {
                handleOpenModalTransferEnvelopes();
                setRefresh(!refresh);
            },
            icon: _jsx(TbTransferIn, { size: 20, className: '' }, void 0),
            id: 2,
            title: 'Transferir Envelopes',
        }
    ];
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: "" + (showTransfersTable ? styles.fisrtContentBoxBreak : styles.fisrtContentBox) }, { children: _jsx(Table, { setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, highLightLine: true, setPage: setPage, lineButton: lineBtnsPurchasedEnvelope, data: items, setData: setItems, 
                            //changeExistentContent={setLibraryState}
                            totalPAges: totalPages, loading: loading, title: 'Envelopes', 
                            //setIdToChange={setIdToChange}
                            refresh: setRefresh, headerButtons: menuBtn, 
                            //editable
                            columns: [
                                { label: 'ID', key: 'id' },
                                { label: 'Data', key: 'date' },
                                { label: 'Quantidade', key: 'quantity' },
                                { label: 'Saldo', key: 'balance' },
                                { label: 'Empresa', key: 'companyName' },
                            ] }, void 0) }), void 0), showTransfersTable &&
                        _jsx("div", { className: 'h-0.5 w-full bg-gray-200' }, void 0), _jsx("div", __assign({ className: "" + (showTransfersTable && styles.showTransfersTable) }, { children: showTransfersTable &&
                            _jsx(_Fragment, { children: _jsx(Table, { setSize: setPageSizeDetails, size: pageSizeDetails, hasNext: hasNextDetails, page: pageDetails, setPage: setPageDetails, lineButton: lineBtnsTransferredEnvelopes, data: transferredEnvelopes, totalPAges: totalPagesDetails, loading: loadingTransferredEnvelopes, setData: setTransferredEnvelopes, title: titleTransferredEnvelopes, columns: [
                                        { label: 'Data', key: 'date' },
                                        { label: 'Quantidade', key: 'quantity' },
                                        { label: 'Empresa', key: 'companyName' },
                                        { label: 'Usuário', key: 'userName' },
                                        { label: 'Quantidade total da transferência', key: 'totalTransferQuantity' },
                                    ] }, void 0) }, void 0) }), void 0)] }), void 0), _jsx(ModalBuyEnvelopes, { refresh: refresh, setRefresh: setRefresh, modalIsOpenBuyEnvelopes: modalIsOpenBuyEnvelopes, handleOpenModalBuyEnvelopes: handleOpenModalBuyEnvelopes, handleCloseModalBuyEnvelopes: handleCloseModalBuyEnvelopes }, void 0), _jsx(ModalTransferEnvelopes, { refresh: refresh, setRefresh: setRefresh, isOpenModalTransferEnvelopes: isOpenModalTransferEnvelopes, handleOpenModalTransferEnvelopes: handleOpenModalTransferEnvelopes, handleCloseModalTransferEnvelopes: handleCloseModalTransferEnvelopes }, void 0), _jsx(ModalConfirmation, { setState: setShowTransfersTable, method: 'delete', url: "/envelopes-management/purchased/" + idPurchasedEnvelope, title: 'esse envelope', refresh: refresh, setRefresh: setRefresh, modalIsOpenConfirmation: isOpenModalConfirmationDeleteEnvelope, handleOpenModalConfirmation: handleOpenModalConfirmationDeleteEnvelope, handlecloseModalConfirmation: handleCloseModalConfirmationDeleteEnvelope }, void 0), _jsx(ModalConfirmation, { method: 'delete', url: "/envelopes-management/transfer-undo/" + idTransferredEnvelope, methodTitle: 'estornar', title: 'essa transferência', refresh: refresh, setRefresh: setRefresh, modalIsOpenConfirmation: isOpenModalConfirmationUndoTransfer, handleOpenModalConfirmation: handleOpenModalConfirmationUndoTransfer, handlecloseModalConfirmation: handleCloseModalConfirmationUndoTransfer }, void 0)] }, void 0));
};
export default EnvelopesManagement;
