var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// Componente input de arquivos, drag and drop
import Dropzone from "react-dropzone";
import DropContainer from "../DropContainer";
import UploadMessege from "../UploadMessege";
import FileList from '../FileList-Uploader';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import './styles.css';
//Componente para anexar arquivos
var Uploader = function (props) {
    var renderDragMessege = function (isDragActive, isDragReject, fileRejections) {
        if (!isDragActive) {
            return _jsx(UploadMessege, { children: "Clique ou arraste seus arquivos" }, void 0);
        }
        if (fileRejections.length > 0 && !isDragReject) {
            return _jsx(UploadMessege, __assign({ type: 'error' }, { children: "Arquivos muito grandes" }), void 0);
        }
        if (isDragReject) {
            return _jsx(UploadMessege, __assign({ type: 'error' }, { children: "Arquivo n\uFFFDo suportado" }), void 0);
        }
        return _jsx(UploadMessege, __assign({ type: 'success' }, { children: "Solte os arquivos" }), void 0);
    };
    var handleRemoveFile = function (val) { return __awaiter(void 0, void 0, void 0, function () {
        var newFile, newFiles;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newFile = props.file;
                    newFiles = newFile[0].filter(function (item) { return item.id !== val; });
                    return [4 /*yield*/, props.stateOfFilesTochange({
                            uploadedFiles: [newFiles]
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var uploader = function (files) { return __awaiter(void 0, void 0, void 0, function () {
        var uploadedFiles, newArr, i;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    uploadedFiles = files.map(function (file) { return ({
                        file: file,
                        id: uniqueId(),
                        name: file.name,
                        readableSize: filesize(file.size),
                        preview: URL.createObjectURL(file),
                        progress: 0,
                        uploaded: false,
                        error: file.size > 900000 ? true : false,
                        url: null,
                    }); });
                    newArr = uploadedFiles;
                    if (props.file) {
                        for (i = 0; i < props.file[0].length; i++) {
                            newArr.push(props.file[0][i]);
                        }
                    }
                    return [4 /*yield*/, props.stateOfFilesTochange({
                            uploadedFiles: [newArr]
                        })];
                case 1:
                    _a.sent();
                    if (!props.setHasImg) return [3 /*break*/, 3];
                    return [4 /*yield*/, props.setHasImg(true)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs("div", __assign({ className: 'containerUploader' }, { children: [_jsx(Dropzone, __assign({ accept: 'image/*', onDropAccepted: function (acepetedFiles) {
                    uploader(acepetedFiles);
                } }, { children: function (_a) {
                    var getRootProps = _a.getRootProps, getInputProps = _a.getInputProps, isDragActive = _a.isDragActive, isDragReject = _a.isDragReject, fileRejections = _a.fileRejections;
                    return ( // fun��o children, padr�o do dropzone
                    _jsxs(DropContainer, __assign({ rootProps: getRootProps, isDragActive: isDragActive, isDragReject: isDragReject, fileRejections: fileRejections }, { children: [_jsx("input", __assign({}, getInputProps()), void 0), renderDragMessege(isDragActive, isDragReject, fileRejections)] }), void 0));
                } }), void 0), !!props.file &&
                _jsx(FileList, { deleteButton: handleRemoveFile, images: props.file[0] }, void 0)] }), void 0));
};
export default Uploader;
