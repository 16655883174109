var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// <reference path="../Company.tsx" />
import { AiOutlineClose } from 'react-icons/ai';
import Modal from 'react-modal';
import useAuth from '../../../hooks/useAuth';
import api from '../../../services/api';
import styles from './styles.module.scss';
import { Alert, Button } from 'template/lib';
import { useState } from 'react';
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');
var ModalConfirmation = function (props) {
    var _a;
    var login = useAuth().login;
    var _b = useState(false), confirmationError = _b[0], setConfirmationError = _b[1];
    var _c = useState(''), errMessage = _c[0], setErrorMessage = _c[1];
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    var handleDelete = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, api.delete(props.url, config)];
                case 1:
                    response = _a.sent();
                    console.log(props.refresh);
                    if (props.refresh == null || props.refresh == undefined) {
                        props.setRefresh(true);
                    }
                    else {
                        props.setRefresh(!props.refresh);
                    }
                    if (props.setState) {
                        props.setState(false);
                    }
                    closeModal();
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    if (err_1.response.data.message) {
                        setErrorMessage(err_1.response.data.message);
                    }
                    else if (err_1.response.data.detail) {
                        setErrorMessage(err_1.response.data.detail);
                    }
                    setConfirmationError(true);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var closeModal = function () {
        props.handlecloseModalConfirmation();
        setErrorMessage('');
        setConfirmationError(false);
    };
    return (_jsx("div", __assign({ className: 'h-24' }, { children: _jsxs(Modal, __assign({ isOpen: props.modalIsOpenConfirmation, shouldCloseOnOverlayClick: false, onRequestClose: props.handlecloseModalConfirmation, overlayClassName: 'react-modal-overly', className: 'react-modal-content', contentLabel: "Example Modal" }, { children: [_jsxs("div", __assign({ className: styles.header }, { children: [_jsx("div", {}, void 0), _jsxs("h2", __assign({ className: ' font-semibold border-b-2 text-lg' }, { children: ["Tem certeza que deseja ", (_a = props.methodTitle) !== null && _a !== void 0 ? _a : 'excluir', " ", props.title, "? "] }), void 0), _jsx(AiOutlineClose, { title: 'Fechar', className: 'cursor-pointer text-sm', onClick: closeModal }, void 0)] }), void 0), confirmationError ? (_jsx("div", __assign({ className: styles.withError }, { children: _jsxs(Alert, { children: [_jsx("strong", { children: "Erro! " }, void 0), errMessage] }, void 0) }), void 0)) : _jsx("div", { className: styles.withOutError }, void 0), _jsx("div", __assign({ className: styles.content }, { children: _jsxs("div", __assign({ className: 'flex w-full p-10 justify-center gap-24 ' }, { children: [_jsx(Button, __assign({ onClick: closeModal }, { children: "Cancelar" }), void 0), _jsx(Button, __assign({ onClick: handleDelete }, { children: "Confirmar" }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default ModalConfirmation;
