var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import api from "../../services/api";
import { Button, InputDate, InputSelect, InputText } from 'template/lib';
import styles from './styles.module.scss';
var Filter = function (props) {
    var title = useTitle().title;
    var login = useAuth().login;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    var _a = useState(''), companyId = _a[0], setCompanyId = _a[1];
    var _b = useState(''), softwareId = _b[0], setSoftwareId = _b[1];
    var _c = useState(), applicationFilter = _c[0], setApplicationFilter = _c[1];
    var _d = useState(), createdByIdFilter = _d[0], setCreatedByIdFilter = _d[1];
    var _e = useState(), requestUserIdFilter = _e[0], setRequestUserIdFilter = _e[1];
    useEffect(function () {
        if (createdByIdFilter) {
            formik.setFieldValue(createdByIdFilter.fieldName, '');
        }
        if (requestUserIdFilter) {
            formik.setFieldValue(requestUserIdFilter.fieldName, '');
        }
        if (companyId) {
            api.get("supports/helpFiltersByCompany?companyId=" + companyId, config).then(function (response) {
                response.data.forEach(function (filter) {
                    switch (filter.fieldName) {
                        case 'CreatedById':
                            setCreatedByIdFilter(null);
                            setCreatedByIdFilter(filter);
                            break;
                        case 'RequestUserId':
                            setRequestUserIdFilter(null);
                            setRequestUserIdFilter(filter);
                            break;
                        default:
                            break;
                    }
                });
            });
        }
        else {
            setCreatedByIdFilter(null);
            setRequestUserIdFilter(null);
        }
    }, [companyId]);
    useEffect(function () {
        if (applicationFilter) {
            formik.setFieldValue(applicationFilter.fieldName, '');
        }
        if (softwareId) {
            api.get("helps/applicationFilter/" + softwareId, config).then(function (response) {
                setApplicationFilter(null);
                setApplicationFilter(response.data);
            });
        }
        else {
            setApplicationFilter(null);
        }
    }, [softwareId]);
    var formik = useFormik({
        initialValues: {},
        enableReinitialize: true,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, props.stateOfFilter(__assign({}, values))];
                    case 1:
                        _a.sent();
                        props.page();
                        return [2 /*return*/];
                }
            });
        }); }
    });
    var applications;
    var requestUsers;
    var createdByUsers;
    if (applicationFilter) {
        applications = {
            label: applicationFilter.fieldDisplay,
            options: applicationFilter.options
        };
    }
    if (requestUserIdFilter) {
        requestUsers = {
            label: requestUserIdFilter.fieldDisplay,
            options: requestUserIdFilter.options
        };
    }
    if (createdByIdFilter) {
        createdByUsers = {
            label: createdByIdFilter.fieldDisplay,
            options: createdByIdFilter.options
        };
    }
    var renderFilter = function (filter) {
        switch (filter.dataType) {
            case 'Text':
                return (_jsx("div", { children: _jsx(InputText, { onChange: formik.handleChange, name: filter.fieldName, label: filter.fieldDisplay }, void 0) }, filter.fieldName));
            case 'Date':
                return (_jsx("div", { children: _jsx(InputDate, { onChange: formik.handleChange, name: filter.fieldName, label: filter.fieldDisplay }, void 0) }, filter.fieldName));
            case 'Select':
                var items = {
                    label: filter.fieldDisplay,
                    options: filter.options
                };
                return (_jsxs(_Fragment, { children: [filter.customFilter == false &&
                            _jsx(_Fragment, { children: _jsx("div", { children: _jsx(InputSelect, { values: items, name: filter.fieldName, onChange: formik.handleChange, valueMember: 'id', displayMember: 'display' }, void 0) }, filter.fieldName) }, void 0), filter.customFilter && filter.fieldName == 'SoftwareId' &&
                            _jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(InputSelect, { values: items, name: filter.fieldName, onChange: function (e) { formik.handleChange(e); setSoftwareId(e.target.value); }, valueMember: 'id', displayMember: 'display' }, void 0) }, filter.fieldName), applicationFilter &&
                                        _jsx("div", { children: _jsx(InputSelect, { values: applications, name: applicationFilter.fieldName, onChange: formik.handleChange, valueMember: 'id', displayMember: 'display' }, void 0) }, applicationFilter.fieldName)] }, void 0), filter.customFilter && filter.fieldName == 'CompanyId' &&
                            _jsxs(_Fragment, { children: [_jsx("div", { children: _jsx(InputSelect, { values: items, name: filter.fieldName, onChange: function (e) { formik.handleChange(e); setCompanyId(e.target.value); }, valueMember: 'id', displayMember: 'display' }, void 0) }, filter.fieldName), requestUserIdFilter &&
                                        _jsx("div", { children: _jsx(InputSelect, { values: requestUsers, name: requestUserIdFilter.fieldName, onChange: formik.handleChange, valueMember: 'id', displayMember: 'display' }, void 0) }, requestUserIdFilter.fieldName), createdByIdFilter &&
                                        _jsx("div", { children: _jsx(InputSelect, { values: createdByUsers, name: createdByIdFilter.fieldName, onChange: formik.handleChange, valueMember: 'id', displayMember: 'display' }, void 0) }, createdByIdFilter.fieldName)] }, void 0)] }, void 0));
            default:
                return null;
        }
    };
    return (_jsx("form", __assign({ onSubmit: formik.handleSubmit }, { children: _jsx("div", __assign({ className: styles.grid }, { children: _jsxs("div", __assign({ className: "grid grid-cols-1 md:grid-cols-" + 1 + " lg:grid-cols-" + 1 + " my-2 justify-items-end" }, { children: [props.data.map(function (filter) { return renderFilter(filter); }), ((login === null || login === void 0 ? void 0 : login.role) === 'Customer' || (login === null || login === void 0 ? void 0 : login.role) === 'CustomerAdmin') &&
                        _jsxs("div", __assign({ className: styles.checkBox }, { children: [_jsx("div", { className: '' }, void 0), _jsx("label", { children: "Mostrar fechados" }, void 0), _jsx("input", { name: 'showClosed', onChange: function (e) { formik.handleChange(e); formik.setFieldValue('showClosed', e.target.checked); }, type: 'checkbox' }, void 0)] }), 'showClosed'), _jsxs("div", __assign({ className: styles.button }, { children: [props.closer && _jsx(Button, __assign({ onClick: props.closer, type: 'button' }, { children: "Cancelar" }), void 0), _jsx(Button, __assign({ type: 'submit' }, { children: "Pesquisar" }), void 0)] }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default Filter;
