var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { BsSearch } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { GrFilter } from "react-icons/gr";
import { useHistory } from "react-router-dom";
import Filter from "../../components/Filter";
import { Table } from 'template/lib';
import useAuth from "../../hooks/useAuth";
import useTitle from "../../hooks/useTitle";
import api from "../../services/api";
var Search = function () {
    var _a = useAuth(), login = _a.login, handleLogout = _a.handleLogout;
    var setTitle = useTitle().setTitle;
    var history = useHistory();
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    // button function for navigate to detail page
    var lineBtnsWithoutAssginTo = [];
    var buttonDetailEvent = {
        name: 'Detalhes',
        icon: _jsx(BsSearch, {}, void 0),
        fn: function (id) {
            //history.push(`/profile/${id}`)
            //Abre o help em uma nova aba.
            window.open("/profileNewPage/" + id, "_blank");
            //Marca o help como visualizado.
            var newhelps = __spreadArray([], helps);
            newhelps.map(function (item) {
                if (item.id === id) {
                    item.viewed = true;
                }
            });
            setHelps(newhelps);
        }
    };
    lineBtnsWithoutAssginTo.push(buttonDetailEvent);
    // buttons for table menu
    var menuButtons = [
        {
            id: 3,
            icon: _jsx(GrFilter, {}, void 0),
            title: 'Filtrar',
            fn: function () {
                if (filter === false) {
                    setFilter(true);
                }
                else {
                    setFilter(false);
                }
            }
        },
        {
            id: 2,
            icon: _jsx(FiPlus, {}, void 0),
            title: 'Novo Suporte',
            fn: function () {
                history.push('/create');
            }
        }
    ];
    var _b = useState([]), helps = _b[0], setHelps = _b[1];
    var _c = useState(1), page = _c[0], setPage = _c[1];
    var _d = useState(15), pageSize = _d[0], setPageSize = _d[1];
    var _e = useState(false), hasNext = _e[0], setHasNext = _e[1];
    var _f = useState(true), filter = _f[0], setFilter = _f[1];
    var _g = useState(0), totalPages = _g[0], setTotalPages = _g[1];
    var _h = useState([]), filterVal = _h[0], setFilterVal = _h[1];
    var _j = useState(), filterState = _j[0], setFilterState = _j[1];
    var _k = useState(true), loading = _k[0], setLoading = _k[1];
    useEffect(function () {
        setTitle('Pesquisa');
        if (!login) {
            history.push('/');
        }
        if (login && Date.now() > (login === null || login === void 0 ? void 0 : login.expiresIn) * 1000) {
            handleLogout();
            history.push('/');
        }
        api.get('supports/helpFilters', config).then(function (response) {
            try {
                setFilterVal(response.data);
            }
            catch (e) {
                handleLogout();
            }
        });
        //creating the filter route 
        var urlTotalPages = "/supports/helpsTotalPages?pagesize=" + pageSize;
        var urlPendingHelps = "/supports/helps?page=" + page + "&pagesize=" + pageSize;
        if (filterState) {
            for (var key in filterState) {
                var value = filterState[key];
                if (value != null && value != undefined && value != '') {
                    urlTotalPages += "&" + key + "=" + value;
                    urlPendingHelps += "&" + key + "=" + value;
                }
            }
        }
        api.get(urlTotalPages, config)
            .then(function (response) {
            setTotalPages(response.data);
            try {
                if (response.data > page) {
                    setHasNext(true);
                }
                else {
                    setHasNext(false);
                }
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        });
        api.get(urlPendingHelps, config)
            .then(function (response) {
            try {
                setHelps(response.data);
            }
            catch (e) {
                handleLogout();
                history.push('/');
            }
        }).then(function () { return setLoading(false); });
    }, [page, pageSize, filterState]);
    function pages() {
        setPage(1);
    }
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: 'pb-20 h-screen ' }, { children: _jsxs("div", __assign({ className: 'overflow-auto h-full' }, { children: [filter &&
                        _jsx("div", __assign({ className: 'm-4' }, { children: _jsx(Filter, { page: pages, data: filterVal, stateOfFilter: setFilterState }, void 0) }), void 0), _jsx(Table, { loading: loading, setSize: setPageSize, size: pageSize, hasNext: hasNext, page: page, setPage: setPage, data: helps, totalPAges: totalPages, headerButtons: menuButtons, lineButton: lineBtnsWithoutAssginTo, exporter: true, title: 'Chamados', columns: [
                            { label: 'ID', key: 'id', eventClick: buttonDetailEvent.fn },
                            { label: 'TFS', key: 'tfsWorkItem' },
                            { label: 'Aplicação', key: 'applicationName' },
                            { label: 'Descrição', key: 'especification', maxLenghtToShowSeeMore: 15 },
                            { label: 'Assinado para', key: 'assignedToName' },
                            { label: 'Status', key: 'statusName' },
                            { label: 'Empresa', key: 'companyName' },
                            { label: 'Usuário', key: 'requestUserName' },
                            { label: 'Data Criação', key: 'creationDateWithTime' },
                            { label: 'Data Conclusão', key: 'closedDateWithTime' },
                        ] }, void 0)] }), void 0) }), void 0) }, void 0));
};
export default Search;
