var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext, useState } from "react";
export var TitleContext = createContext({});
export function TitleContextProvider(props) {
    var _a = useState(), title = _a[0], setTitle = _a[1];
    return (_jsx(TitleContext.Provider, __assign({ value: { title: title, setTitle: setTitle } }, { children: props.children }), void 0));
}
