var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { BsSearch } from 'react-icons/bs';
import { MdAutorenew } from 'react-icons/md';
import api from '../../services/api';
import useAuth from '../../hooks/useAuth';
import useTitle from '../../hooks/useTitle';
import { FiDownload } from "react-icons/fi";
import { GiCancel, GiConfirmed } from 'react-icons/gi';
import { Table } from 'template/lib';
import styles from './styles.module.scss';
import apiUrl from '../../services/apiUrl';
var Licenses = function () {
    var _a = useState([]), details = _a[0], setDetails = _a[1];
    var _b = useState(1), pageDetails = _b[0], setPageDetails = _b[1];
    var _c = useState(10), pageSizeDetails = _c[0], setPageSizeDetails = _c[1];
    var _d = useState(false), hasNextDetails = _d[0], setHasNextDetails = _d[1];
    var _e = useState(0), totalPagesDetails = _e[0], setTotalPagesDetails = _e[1];
    var _f = useState(false), detailsTable = _f[0], setDetailsTable = _f[1];
    var _g = useState(0), save = _g[0], setSave = _g[1];
    var _h = useState(1), page = _h[0], setPage = _h[1];
    var _j = useState(10), pageSize = _j[0], setPageSize = _j[1];
    var _k = useState(false), hasNext = _k[0], setHasNext = _k[1];
    var _l = useState(0), totalPages = _l[0], setTotalPages = _l[1];
    var _m = useState([]), items = _m[0], setItems = _m[1];
    var _o = useState(0), idLicense = _o[0], setIdLicense = _o[1];
    var _p = useState(true), loading = _p[0], setLoading = _p[1];
    var _q = useState(true), loadingDetails = _q[0], setLoadingDetails = _q[1];
    var _r = useState(''), titleOfLicense = _r[0], setTitleOfLicense = _r[1];
    var login = useAuth().login;
    var setTitle = useTitle().setTitle;
    var config = {
        headers: { Authorization: "Bearer " + (login === null || login === void 0 ? void 0 : login.token) }
    };
    useEffect(function () {
        setTitle('Licenças');
        setDetailsTable(false);
        api.get("licenses/licensesByCompanyIdReadOnly?companyId=" + (login === null || login === void 0 ? void 0 : login.companyId), config).then(function (response) {
            setItems(response.data);
        }).catch(function (err) { return console.log(err.response.status); }).then(function () { return setLoading(false); });
    }, []);
    var lineBtnsWithoutAssginToDetails = [
        {
            name: 'Resetar Licença',
            icon: _jsx(MdAutorenew, { size: 16, className: 'text-sm' }, void 0),
            fn: function (id) {
                setSave(id);
            }
        }
    ];
    var lineBtnsWithoutAssginToDetailsSave = [
        {
            name: 'Confirmar',
            icon: _jsx(GiConfirmed, { className: 'hover:text-green-500 text-green-700', size: 16 }, void 0),
            id: save,
            fn: function (id) {
                api.patch("licenses/" + idLicense + "/licenseRenovations/" + id + "/reset", '', config).then(function (response) { return console.log(response.data); }).then(function () { return setSave(0); }).then(function () {
                    api.get("licenses/" + idLicense + "/licenseRenovations", config).then(function (response) {
                        setTitleOfLicense(response.data.renovationTitle);
                        setDetails(response.data.renovations);
                    });
                });
            }
        },
        {
            name: 'Cancelar',
            icon: _jsx(GiCancel, { className: 'hover:text-red-400 text-red-600', size: 16 }, void 0),
            id: save,
            fn: function (id) {
                setSave(-1);
            }
        }
    ];
    var lineBtnsWithoutAssginTo = [
        {
            name: 'Detalhes',
            icon: _jsx(BsSearch, { className: '' }, void 0),
            fn: function (id) {
                api.get("licenses/" + id + "/licenseRenovations", config).then(function (response) {
                    setIdLicense(id);
                    setDetails(response.data.renovations);
                    setTitleOfLicense(response.data.renovationTitle);
                    setDetailsTable(true);
                }).then(function () { return setLoadingDetails(false); });
            }
        },
        {
            name: 'Download Instalador',
            icon: _jsx(FiDownload, { size: 16 }, void 0),
            fn: function (id) {
                window.open(apiUrl + "licenses/" + id + "/download-installer", "_blank");
            },
            hideWithCondition: true
        }
    ];
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: styles.container }, { children: [_jsx("div", __assign({ className: "" + (detailsTable ? styles.fisrtContentBoxBreak : styles.fisrtContentBox) }, { children: _jsx(Table, { setSize: setPageSize, hasNext: hasNext, page: page, highLightLine: true, setPage: setPage, data: items, loading: loading, totalPAges: totalPages, lineButton: lineBtnsWithoutAssginTo, title: 'Licen\u00E7as', columns: [
                            { label: 'ID', key: 'id' },
                            { label: 'Produto', key: 'product' },
                            { label: 'Quantidade', key: 'quantity' },
                        ] }, void 0) }), void 0), detailsTable &&
                    _jsx("div", { className: 'h-0.5 w-full bg-gray-200' }, void 0), _jsx("div", __assign({ className: "" + (detailsTable && styles.detailsTable) }, { children: detailsTable &&
                        _jsx(_Fragment, { children: _jsx(Table, { setSize: setPageSizeDetails, hasNext: hasNextDetails, page: pageDetails, setPage: setPageDetails, data: details, lineButtonForEach: lineBtnsWithoutAssginToDetailsSave, loading: loadingDetails, totalPAges: totalPagesDetails, lineButton: lineBtnsWithoutAssginToDetails, title: titleOfLicense, columns: [
                                    { label: 'ID', key: 'id' },
                                    { label: 'Data Renovação', key: 'dateRenovation' },
                                    { label: 'Data Expiração', key: 'dateExpiration' },
                                    { label: 'Número da Licença', key: 'licenseNumber' },
                                    { label: 'Última Validação', key: 'dateLastValidation' },
                                    { label: 'Detalhes', key: 'renovationDetails' },
                                    { label: 'Usuário do Domínio', key: 'userDomain' },
                                    { label: 'Versão Instalada', key: 'softwareLicence' }
                                ] }, void 0) }, void 0) }), void 0)] }), void 0) }, void 0));
};
export default Licenses;
