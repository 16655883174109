var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import LogoImg from '../../assets/images/logo_cabecalho.png'; // PRODU��O
//import LogoImg from '../../assets/images/logo_cabecalho_teste.png' // TODO: TESTE
import { Link, useHistory, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import api from '../../services/api';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import styles from './styles.module.scss';
import { Alert } from 'template/lib';
var override = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f; \n"], ["\n  display: block;\n  margin: 0 auto;\n  border-color: #2f2f2f; \n"])));
var userSchema = Yup.object().shape({
    newPassword: Yup.string()
        .min(8, 'Por favor, informe uma senha com pelo menos 8 caracteres.')
        .max(20, 'Por favor, informe uma senha com no m�ximo 20 caracteres.')
        .required('Por favor, informe uma senha.'),
    confirmPasswd: Yup.string()
        .required('Por favor, confirme a senha')
        .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais.'),
});
var ChangePassword = function () {
    var params = useParams();
    var id = params.id;
    var code = params.code;
    var history = useHistory();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(false), signInError = _b[0], setSignInError = _b[1];
    var _c = useAuth(), handleSetLogin = _c.handleSetLogin, login = _c.login;
    var formik = useFormik({
        initialValues: {
            login: id,
            verificationCode: code,
            newPassword: '',
            confirmPasswd: '',
        }, validationSchema: userSchema,
        onSubmit: function (values) { return __awaiter(void 0, void 0, void 0, function () {
            var response, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setLoading(true);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, api.patch("auth/changePassword?login=" + values.login + "&verificationCode=" + values.verificationCode, values)];
                    case 2:
                        response = _b.sent();
                        return [4 /*yield*/, handleSetLogin(response.data.token)];
                    case 3:
                        _b.sent();
                        setLoading(false);
                        history.push('profile');
                        return [3 /*break*/, 5];
                    case 4:
                        _a = _b.sent();
                        setLoading(false);
                        setSignInError(true);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); }
    });
    useEffect(function () {
        if (login) {
            history.push('/profile');
        }
    }, [login]);
    return (_jsx("div", __assign({ className: styles.body }, { children: _jsxs("form", __assign({ onSubmit: formik.handleSubmit }, { children: [_jsx("div", __assign({ className: styles.logo }, { children: _jsx("img", { className: styles.logoImg, src: LogoImg, alt: 'logoImage' }, void 0) }), void 0), _jsxs("div", __assign({ className: styles.inputText }, { children: [_jsx("input", { disabled: loading, name: 'newPassword', value: formik.values.newPassword, onChange: formik.handleChange, type: "password", placeholder: "Senha" }, void 0), _jsxs("p", __assign({ className: styles.errorMessage }, { children: [" ", formik.errors.newPassword && formik.touched.newPassword && _jsx("i", { children: formik.errors.newPassword }, void 0)] }), void 0)] }), void 0), _jsxs("div", __assign({ className: styles.inputText }, { children: [_jsx("input", { disabled: loading, name: 'confirmPasswd', value: formik.values.confirmPasswd, onChange: formik.handleChange, type: "password", placeholder: "Confirma senha" }, void 0), _jsxs("p", __assign({ className: styles.errorMessage }, { children: [" ", formik.errors.confirmPasswd && formik.touched.confirmPasswd && _jsx("i", { children: formik.errors.confirmPasswd }, void 0)] }), void 0)] }), void 0), _jsx("button", __assign({ disabled: loading, className: (loading ? styles.loading : '') + " ", type: 'submit' }, { children: !loading ? 'Entrar' : _jsx(ClipLoader, { css: override, loading: loading, size: 16 }, void 0) }), void 0), signInError &&
                    _jsxs(Alert, { children: [_jsx("strong", { children: "Erro!" }, void 0), " Por favor, revise seus dados."] }, void 0), _jsx("div", __assign({ className: styles.linkBack }, { children: _jsx(Link, __assign({ to: '/' }, { children: _jsxs("div", { children: [_jsx(RiArrowGoBackFill, { size: 14 }, void 0), "Voltar"] }, void 0) }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default ChangePassword;
var templateObject_1;
